import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-542630d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Tool" }
const _hoisted_2 = { class: "Tool__header" }
const _hoisted_3 = { class: "Tool__title" }
const _hoisted_4 = { class: "Tool__body" }
const _hoisted_5 = { class: "body__text" }
const _hoisted_6 = { class: "body__text" }
const _hoisted_7 = { class: "body__text" }
const _hoisted_8 = { class: "body__text" }
const _hoisted_9 = { class: "body__text" }
const _hoisted_10 = { class: "body__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, "Инструмент №" + _toDisplayString(_ctx.index + 1), 1),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-default btn-small",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitDelete && _ctx.emitDelete(...args)))
      }, " Удалить ")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, "Артикул: " + _toDisplayString(_ctx.tool.vendor_code), 1),
      _createElementVNode("p", _hoisted_6, "Тип: " + _toDisplayString(_ctx.tool.type), 1),
      _createElementVNode("p", _hoisted_7, "Серийный номер: " + _toDisplayString(_ctx.tool.serial_number), 1),
      _createElementVNode("p", _hoisted_8, "Описание: " + _toDisplayString(_ctx.tool.description), 1),
      _createElementVNode("p", _hoisted_9, "Есть аккумулятор: " + _toDisplayString(_ctx.tool.battery ? 'Да' : 'Нет'), 1),
      _createElementVNode("p", _hoisted_10, "Есть зарядное устройство: " + _toDisplayString(_ctx.tool.charger ? 'Да' : 'Нет'), 1)
    ])
  ]))
}