<template>
    <div class="AuthorizeLoader">
        <div class="AuthorizeLoader__content">
            <div class="loader large green" />
            <p class="AuthorizeLoader__text">Загрузка, пожалуйста подождите ...</p>
        </div>
    </div>
</template>

<style lang="scss" scoped src="./index.scss" />
