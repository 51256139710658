
import { defineComponent, PropType } from 'vue'
import { ToolT } from '@/common.types'

export default defineComponent({
    props: {
        tool: {
            type: Object as PropType<ToolT>,
            default: null,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    emits: ['delete'],
    setup(props, { emit }) {
        const emitDelete = () => {
            emit('delete', props.index)
        }

        return { emitDelete }
    },
})
