
import { defineComponent, onMounted, reactive, toRaw } from 'vue'
import moment from 'moment'

// components
import ToolForm from '@/components/ToolForm/index.vue'
import Tool from '@/components/Tool/index.vue'
import Alert from '@/components/Alert/index.vue'
import Map from '@/components/Map/index.vue'
import Modal from '@/components/Modal/index.vue'
import MapSearch from '@/components/MapSearch/index.vue'
import AuthorizeLoader from '@/components/AuthorizeLoader/index.vue'

// icons
import SparkLogo from '@/assets/icons/spark-logo.svg'
import BoschLogo from '@/assets/icons/bosch-logo.svg'
import iCheck from '@/assets/icons/check.svg'

// directives
import { maska } from 'maska'

// validations
import {
    SenderFormValidation,
    CargoFormValidation,
    AgreementsValidation,
    StateFormValidation,
} from '@/validations'

import useVuelidate from '@vuelidate/core'

// constants
import {
    ORDER_DEFAULT_COUNTRY_ID,
    ORDER_DEFAULT_PAYMENT_METHOD,
    ORDER_DEFAULT_PAYMENT_TYPE,
    ORDER_DEFAULT_SENDER_TITLE,
    ORDER_DEFAULT_SHIPMENT_TYPE,
    ORDER_DEFAULT_TAKE_TIME,
    ORDER_DEFAULT_TITLE,
} from '@/common.constants'

import { MAP_KEYS } from '@/map.constants'

// functions
import {
    Authorize,
    createOrder,
    geocodeAddressByCoords,
    geocodeAddressByGisCoords,
    geocodeCoordsByAddress,
    loadCities,
    loadServices,
    getByCityId,
} from '@/requests'

import { useMaska } from '@/useMaska'

// types
import { CityT, OrderPayloadT, ServiceT, ToolT } from '@/common.types'
import {
    getCoordsFromYandexGeocodeResponse,
    getFormattedResponseFromGisItem,
    getFormattedResponseFromYandexGeoObject,
} from './map.functions'
import { MapSearchResultT } from './map.types'
import { WarehouseT } from './warehouses.types'

export default defineComponent({
    directives: {
        maska,
    },
    components: {
        'authorize-loader': AuthorizeLoader,
        'tool-form': ToolForm,
        tool: Tool,
        alert: Alert,
        modal: Modal,
        'map-search': MapSearch,
        'map-component': Map,
        'spark-logo': SparkLogo,
        'bosch-logo': BoschLogo,
        'icon-check': iCheck,
    },
    setup() {
        const state = reactive({
            is_submited: false,
            tools: [] as ToolT[],
            is_adding_tool: true,
            is_loading: false,
            map_opened: false,
            sender_city: null as WarehouseT | null,
        })

        const sender_state = reactive({
            fio: null as string | null,
            phone: null as string | null,
            city: null as CityT | null,
            street: null as string | null,
            house: null as string | null,
            email: null as string | null,
            service_center: null as ServiceT | null,
            customer_requirement: null as string | null,
            full_address: null as string | null,
            latitude: null as number | null,
            longitude: null as number | null,
        })

        const cargo_state = reactive({
            weight: null as string | null,
            length: null as string | null,
            height: null as string | null,
            width: null as string | null,
        })

        const agreements_state = reactive({
            agree_service: false,
            agree_guarantee: false,
            agree_contract: false,
        })

        const additional_state = reactive({
            cities: [] as CityT[],
            is_cities_loading: false,
            services: [] as ServiceT[],
            is_services_loading: false,
        })

        const map_state = reactive({
            instance: null,
            is_yandex: true,
            is_gis: false,
        })

        const address_state = reactive({
            is_loading: false,
            is_failed: false,
            is_finded: false,
            address: null as string | null,
            street: null as string | null,
            house: null as string | null,
            longitude: 0,
            latitude: 0,
        })

        const auth_state = reactive({
            is_loading: false,
        })

        const authorizeHandler = async () => {
            try {
                auth_state.is_loading = true
                await Authorize()
            } catch (error) {
                console.error(error)
                alert('Ошибка авторизации, обновите страницу')
            } finally {
                auth_state.is_loading = false
            }
        }

        const loadCitiesHandler = async () => {
            try {
                additional_state.is_cities_loading = true
                const { data } = await loadCities()
                additional_state.cities = data.data
            } catch (error) {
                console.error(error)
                alert('Ошибка загрузки городов')
            } finally {
                additional_state.is_cities_loading = false
            }
        }

        const state_form$ = useVuelidate(StateFormValidation, state, { $scope: false })
        const sender_form$ = useVuelidate(SenderFormValidation, sender_state, { $scope: false })
        const cargo_form$ = useVuelidate(CargoFormValidation, cargo_state, { $scope: false })
        const agreements_form$ = useVuelidate(AgreementsValidation, agreements_state, {
            $scope: false,
        })

        const loadApp = async () => {
            await authorizeHandler()
            await loadCitiesHandler()
        }

        onMounted(loadApp)

        const onLoadServices = async () => {
            if (!sender_state.city) return
            sender_state.service_center = null

            try {
                additional_state.is_services_loading = true

                const services = await loadServices(sender_state.city.id)
                additional_state.services = services
            } catch (error) {
                console.error(error)
                alert('При загрузке сервисных центров произошла ошибка')
            } finally {
                additional_state.is_services_loading = false
            }
        }

        const onGetByCityId = async () => {
            if (!sender_state.city) return
            sender_state.service_center = null

            try {
                additional_state.is_services_loading = true

                const response = await getByCityId(sender_state.city.id)
                state.sender_city = response.data.data
            } catch (error) {
                console.error(error)
                alert('К сожалению, нет возможности осуществить доставку Вашего инструмента. Просим Вас связаться с нами по электронной почте service.pt.ka@bosch.com')
            } finally {
                additional_state.is_services_loading = false
            }
        }

        const onCitySelected = () => {
            onLoadServices()
            onGetByCityId()
        }

        const addTool = (tool: ToolT) => {
            state.tools.push(tool)
            state.is_adding_tool = false
        }

        const deleteTool = (tool_index: number) => {
            state.tools.splice(tool_index, 1)

            if (state.tools.length < 1) {
                state.is_adding_tool = true
            }
        }

        const submitOrder = async () => {
            state_form$.value.$touch()
            sender_form$.value.$touch()
            cargo_form$.value.$touch()
            agreements_form$.value.$touch()

            if (
                state_form$.value.$invalid ||
                sender_form$.value.$invalid ||
                cargo_form$.value.$invalid ||
                agreements_form$.value.$invalid
            ) {
                return
            }

            if (
                !sender_state.city ||
                !sender_state.fio ||
                !sender_state.phone ||
                !sender_state.email ||
                !sender_state.service_center ||
                !sender_state.customer_requirement ||
                !sender_state.full_address ||
                !sender_state.latitude ||
                !sender_state.longitude
            ) {
                return
            }

            try {
                state.is_loading = true
                const take_date = moment().add(1, 'days').format('YYYY-MM-DD')
                const { unmaskNumbers } = useMaska()

                const order_payload: OrderPayloadT = {
                    title: ORDER_DEFAULT_TITLE,
                    city_id: sender_state.city.id,
                    country_id: ORDER_DEFAULT_COUNTRY_ID,
                    street: sender_state.street,
                    house: sender_state.house,
                    index: null,
                    sender_title: ORDER_DEFAULT_SENDER_TITLE,
                    sender_name: sender_state.fio,
                    sender_phone: unmaskNumbers(sender_state.phone),
                    sender_email: sender_state.email,
                    receiver_id: sender_state.service_center.id,
                    customer_requirement: sender_state.customer_requirement,
                    full_address: sender_state.full_address,
                    latitude: sender_state.latitude,
                    longitude: sender_state.longitude,
                    order_logistics_info: {
                        weight: Number(cargo_state.weight),
                        length: Number(cargo_state.length),
                        width: Number(cargo_state.width),
                        height: Number(cargo_state.height),
                        payment_method: ORDER_DEFAULT_PAYMENT_METHOD,
                        payment_type: ORDER_DEFAULT_PAYMENT_TYPE,
                        shipment_type: ORDER_DEFAULT_SHIPMENT_TYPE,
                        take_date,
                        take_time: ORDER_DEFAULT_TAKE_TIME,
                    },
                    tool: toRaw(state.tools),
                }

                await createOrder(toRaw(order_payload))
                state.is_submited = true
            } catch (error) {
                alert('При создании заказа произошла ошибка')
                console.error(error)
            } finally {
                state.is_loading = false
            }
        }

        const getClickedAdressYandex = async (coords: number[]) => {
            try {
                address_state.is_loading = true

                const api_response = await geocodeAddressByCoords(coords)
                if (!api_response) return

                const first_feature_member =
                    api_response.data.response.GeoObjectCollection.featureMember[0]
                if (!first_feature_member) return

                const formatted_response =
                    getFormattedResponseFromYandexGeoObject(first_feature_member)

                address_state.address = formatted_response.full_address
                address_state.street = formatted_response.street
                address_state.house = formatted_response.house

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const setMapCenter = (coords: number[]) => {
            // @ts-ignore
            map_state.instance.panTo.bind(toRaw(map_state.instance))(coords)
        }

        const getCoordsByAdressYandex = async () => {
            if (!sender_state.city) return

            if (state.sender_city && state.sender_city.latitude && state.sender_city.longitude) {
                setMapCenter([state.sender_city.latitude, state.sender_city.longitude])

                if (sender_state.city.coordinates) {
                    // @ts-ignore
                    toRaw(map_state.instance).options.set(
                        'restrictMapArea',
                        sender_state.city.coordinates
                    )
                }

                return
            }

            try {
                const api_response = await geocodeCoordsByAddress(sender_state.city.name)
                const first_member = api_response.data.response.GeoObjectCollection.featureMember[0]

                if (!api_response) {
                    console.error('Не удалось получить координаты города', sender_state.city.name)
                    return
                }

                const formatted_coords = getCoordsFromYandexGeocodeResponse(
                    first_member.GeoObject.Point.pos
                )
                setMapCenter(formatted_coords)
            } catch (error) {
                console.error(error)
            }
        }

        const getClickedAddressGis = async (coords: number[]) => {
            try {
                address_state.is_loading = true

                const api_response = await geocodeAddressByGisCoords(coords)
                if (!api_response) return

                const first_item = api_response.data.result.items[0]
                if (!first_item) return

                const formatted_response = getFormattedResponseFromGisItem(first_item)

                address_state.address = formatted_response.full_address

                address_state.is_failed = false
                address_state.is_finded = true
            } catch (error) {
                console.error(error)
                address_state.is_failed = true
            } finally {
                address_state.is_loading = false
            }
        }

        const mapCreated = (map_instance: any) => {
            map_state.instance = map_instance
            getCoordsByAdressYandex()
        }

        const setMapPlacemark = (coords: number[]) => {
            // @ts-ignore
            map_state.instance.geoObjects.removeAll.bind(toRaw(map_state.instance.geoObjects))()
            // @ts-ignore
            const placemark = new window.ymaps.Placemark(coords, null, {
                preset: 'islands#greenDotIcon',
            })
            // @ts-ignore
            map_state.instance.geoObjects.add.bind(toRaw(map_state.instance.geoObjects))(placemark)
        }

        const mapClicked = (coords: number[]) => {
            sender_state.latitude = coords[0]
            sender_state.longitude = coords[1]

            address_state.latitude = coords[0]
            address_state.longitude = coords[1]

            setMapPlacemark(coords)
            setMapCenter(coords)

            if (map_state.is_yandex) {
                getClickedAdressYandex(coords)
                return
            }

            if (map_state.is_gis) {
                getClickedAddressGis(coords)
                return
            }
        }

        const setFullAddress = () => {
            sender_state.full_address = address_state.address
            sender_state.street = address_state.street

            sender_state.latitude = address_state.latitude
            sender_state.longitude = address_state.longitude

            state.map_opened = false
            address_state.address = null
            address_state.is_finded = false
        }

        const mapSwitched = (map_key: string) => {
            if (map_key === MAP_KEYS.yandex) {
                map_state.is_yandex = true
                map_state.is_gis = false
            }

            if (map_key === MAP_KEYS.gis) {
                map_state.is_gis = true
                map_state.is_yandex = false
            }
        }

        const resultFinded = (result: MapSearchResultT) => {
            setMapPlacemark(result.points)
            setMapCenter(result.points)

            address_state.is_finded = true

            address_state.address = result.full_address
            address_state.street = result.street
            address_state.house = result.house

            address_state.latitude = result.points[0]
            address_state.longitude = result.points[1]
        }

        const openMap = () => {
            if (!sender_state.city) {
                sender_form$.value.city.$touch()
                return
            }

            state.map_opened = true

            address_state.is_loading = false
            address_state.is_failed = false
            address_state.is_finded = false

            address_state.address = null
            address_state.street = null
            address_state.house = null
            address_state.latitude = 0
            address_state.longitude = 0

            if (state.sender_city) {
                address_state.address = state.sender_city.full_address
                address_state.street = state.sender_city.street
                address_state.house = state.sender_city.house
                address_state.latitude = state.sender_city.latitude
                address_state.longitude = state.sender_city.longitude
            }
        }

        return {
            state,
            sender_state,
            cargo_state,
            agreements_state,
            additional_state,
            auth_state,
            state_form$,
            sender_form$,
            cargo_form$,
            agreements_form$,
            onCitySelected,
            addTool,
            deleteTool,
            submitOrder,
            mapClicked,
            mapCreated,
            address_state,
            setFullAddress,
            mapSwitched,
            map_state,
            resultFinded,
            openMap,
        }
    },
})
