import axios, { AxiosResponse } from 'axios'

import { CitiesRepsonseT, ServicesRepsonseT, ServiceT, OrderPayloadT } from './common.types'

import { YandexGeocodeResponse, GisSuggestsResponse, GisGeocodeResponse } from './map.types'
import { WarehousesGetByCityIdResponseT } from '@/warehouses.types'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
const GATEWAY_URL = process.env.VUE_APP_GATEWAY_URL

/**
 * Функция для получение environments
 */
function getAppEnvironments(): { grant_type: string; client_id: number; client_secret: string } {
    const grant_type = process.env.VUE_APP_API_GRANT_TYPE || ''
    const client_id = Number(process.env.VUE_APP_API_CLIENT_ID) || 0
    const client_secret = process.env.VUE_APP_API_CLIENT_SECRET || ''

    return { grant_type, client_id, client_secret }
}

export const loadCities = (): Promise<AxiosResponse<CitiesRepsonseT>> => {
    return axios.get('/partners/bosch/get_sender_cities')
}

export const Authorize = async (): Promise<string> => {
    const { grant_type, client_id, client_secret } = getAppEnvironments()

    const credentials = {
        grant_type,
        client_id,
        client_secret,
    }

    const data: AxiosResponse<{ access_token: string }> = await axios.post(
        `${GATEWAY_URL}/oauth/token`,
        credentials
    )

    axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`
    return data.data.access_token
}

export const loadServices = async (city_id: number): Promise<ServiceT[]> => {
    const data: AxiosResponse<ServicesRepsonseT> = await axios.get(
        `/partners/bosch/get_receivers/${city_id}`
    )
    return data.data.data
}

export const createOrder = async (order_payload: OrderPayloadT): Promise<void> => {
    return axios.post('/partners/bosch/order', order_payload)
}

export const getByCityId = (
    city_id: number
): Promise<AxiosResponse<WarehousesGetByCityIdResponseT>> => {
    return axios.get(`/warehouses/info/${city_id}`)
}

// map requests

const YANDEX_API_LANG = 'ru_RU'
const YANDEX_GEOCODE_KEY = 'f54e428c-22e6-427d-8f09-b2a41f6588ba'
const YANDEX_GEOCODE_URL = 'https://geocode-maps.yandex.ru/1.x'

const GIS_API_LANG = 'ru_KZ'
const GIS_GEOCODE_KEY = 'rupuxx3048'
const GIS_GEOCODE_URL = 'https://catalog.api.2gis.com/3.0/items/geocode'
const GIS_SUGGEST_URL = 'https://catalog.api.2gis.com/3.0/suggests'

/**
 * Геокодирование координат в адрес (Yandex)
 * @param coords Координаты для получения адреса
 * @returns Promise<AxiosResponse<any>>
 */
export const geocodeAddressByCoords = (
    coords: number[]
): Promise<AxiosResponse<YandexGeocodeResponse>> => {
    const params = {
        apikey: YANDEX_GEOCODE_KEY,
        format: 'json',
        lang: YANDEX_API_LANG,
        geocode: `${coords[1]},${coords[0]}`,
    }

    return axios.get(`${YANDEX_GEOCODE_URL}/`, { params })
}
/**
 * Геокодирование адреса в координаты (Yandex)
 * @param address Адрес для получаения координат
 * @returns Promise<AxiosResponse<any>>
 */
export const geocodeCoordsByAddress = (
    address: string
): Promise<AxiosResponse<YandexGeocodeResponse>> => {
    const params = {
        apikey: YANDEX_GEOCODE_KEY,
        format: 'json',
        lang: YANDEX_API_LANG,
        geocode: address,
    }

    return axios.get(`${YANDEX_GEOCODE_URL}/`, { params })
}

export const geocodeAddressList = (
    address: string
): Promise<AxiosResponse<YandexGeocodeResponse>> => {
    const params = {
        apikey: YANDEX_GEOCODE_KEY,
        format: 'json',
        lang: YANDEX_API_LANG,
        geocode: address,
    }

    return axios.get(YANDEX_GEOCODE_URL, { params })
}

export const geocodeAddressListByGis = (
    address: string
): Promise<AxiosResponse<GisSuggestsResponse>> => {
    const params = {
        q: address,
        locale: GIS_API_LANG,
        fields: 'items.point,items.adm_div',
        key: GIS_GEOCODE_KEY,
    }

    return axios.get(GIS_SUGGEST_URL, { params })
}

export const geocodeAddressByGisCoords = (
    coords: number[]
): Promise<AxiosResponse<GisGeocodeResponse>> => {
    const params = {
        lon: coords[1],
        lat: coords[0],
        fields: 'items.full_address_name',
        locale: GIS_API_LANG,
        key: GIS_GEOCODE_KEY,
    }

    return axios.get(GIS_GEOCODE_URL, { params })
}
