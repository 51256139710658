
import { defineComponent, PropType, reactive, toRaw } from 'vue'
import moment from 'moment'

// types
import { ToolT } from '@/common.types'

// constants
import { CargoTypes } from '@/common.constants'

// validations
import { ToolFormValidation } from '@/validations'
import useVuelidate from '@vuelidate/core'

export default defineComponent({
    emits: ['submit'],
    setup(_, { emit }) {
        const state = reactive({
            article: null as string | null,
            type: null as string | null,
            serial_number: null as string | null,
            sell_date: null as string | null,
            failure_description: null as string | null,
            selected_cargo_types: [],
        })

        const img_state = reactive({
            is_article_img_showed: false,
            is_type_img_showed: false,
            is_serial_number_img_showed: false,
        })

        const tool_form$ = useVuelidate(ToolFormValidation, state, { $scope: false })

        const emitSubmit = () => {
            tool_form$.value.$touch()
            if (tool_form$.value.$invalid) return

            if (
                !state.article ||
                !state.type ||
                !state.serial_number ||
                !state.sell_date ||
                !state.failure_description
            )
                return

            const date = moment(state.sell_date).format('YYYY-MM-DD')
            const battery = state.selected_cargo_types.some((type_id) => type_id === 1)
            const charger = state.selected_cargo_types.some((type_id) => type_id === 2)

            const tool_payload: ToolT = {
                vendor_code: state.article,
                type: state.type,
                serial_number: state.serial_number,
                date,
                description: state.failure_description,
                battery,
                charger,
            }

            emit('submit', toRaw(tool_payload))
        }

        return { state, img_state, tool_form$, CargoTypes, emitSubmit }
    },
})
