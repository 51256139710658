import { AuthCredentialsT } from './common.types'

export const CargoTypes: { id: number; name: string }[] = [
    { id: 1, name: 'Есть аккумулятор' },
    { id: 2, name: 'Есть зарядное устройство' },
]

export const ORDER_DEFAULT_TITLE = 'Bosch | Тех. сервис | Заказ'
export const ORDER_DEFAULT_COUNTRY_ID = 1
export const ORDER_DEFAULT_SENDER_TITLE = 'Bosch | Тех. сервис | Шаблон Отправителя'
export const ORDER_DEFAULT_PAYMENT_METHOD = 1
export const ORDER_DEFAULT_PAYMENT_TYPE = 1
export const ORDER_DEFAULT_SHIPMENT_TYPE = 1
export const ORDER_DEFAULT_TAKE_TIME = '15:00'
