
import { defineComponent, PropType, reactive } from 'vue'
import { useDebounce } from 'vue-composable'

import { OptionT, YandexFormattedListItem, YandexGeoObjectT } from '@/map.types'
import { MapSearchResultT } from '@/map.types'

import { geocodeCoordsByAddress, geocodeAddressListByGis } from '@/requests'
import {
    getFormattedResponseFromYandexGeoObject,
    getCoordsFromYandexGeocodeResponse,
} from '@/map.functions'

export default defineComponent({
    props: {
        city: {
            type: Object as PropType<OptionT<number>>,
            default: null,
        },
        geocodeByYandex: {
            type: Boolean,
            default: true,
        },
        geocodeByGis: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['select'],
    setup(props, { emit }) {
        const searchState = reactive({
            query: null,
            last_query: null,
            results: [] as any[],
            state: {
                is_loading: false,
                is_failed: false,
            },
        })

        const search = useDebounce(async () => {
            try {
                searchState.state.is_loading = true
                searchState.results = []
                searchState.last_query = searchState.query

                if (!searchState.query) return

                let response = [] as any[]

                const query = props.city
                    ? `${props.city.name}, ${searchState.query}`
                    : searchState.query

                if (!query) return

                if (props.geocodeByYandex) {
                    const api_response = await geocodeCoordsByAddress(query)
                    const feature_members =
                        api_response.data.response.GeoObjectCollection.featureMember

                    if (!feature_members || !feature_members.length) return []

                    const mapped_data: YandexFormattedListItem[] = feature_members.map(
                        (d: YandexGeoObjectT, index: number) => {
                            const formatted_response = getFormattedResponseFromYandexGeoObject(d)

                            return {
                                id: index,
                                name: d.GeoObject.name,
                                description: d.GeoObject.description,
                                full_address: formatted_response.full_address,
                                points: getCoordsFromYandexGeocodeResponse(d.GeoObject.Point.pos),
                                street: formatted_response.street,
                                house: formatted_response.house,
                            }
                        }
                    )

                    response = mapped_data
                }

                if (props.geocodeByGis) {
                    const api_response = await geocodeAddressListByGis(query)

                    const mapped_response = api_response.data.result.items.map(
                        (item: any, index: number) => {
                            const points = [item.point.lat, item.point.lon]

                            let country = '-'
                            let city = '-'
                            let hint = '-'

                            if (item.adv_div && item.adv_div.length) {
                                item.adm_div.forEach((div: any) => {
                                    if (div.type === 'country') country = div.name
                                    if (div.type === 'city') city = div.name
                                })

                                if (country === '-') country = item.adm_div[0].name
                                if (city === '-') city = item.adm_div[item.adm_div.length - 1].name

                                hint = `${country}, ${city}`
                            } else {
                                hint = item.name
                            }

                            return {
                                id: index,
                                name: item.full_name || `${item.address_name}, ${item.name}`,
                                full_address:
                                    item.full_name || `${item.address_name}, ${item.name}`,
                                description: hint,
                                points,
                                // TODO
                                house: null,
                                street: null,
                            }
                        }
                    )

                    response = mapped_response
                }

                searchState.results = response
                searchState.state.is_failed = false
            } catch (error) {
                console.error(error)
                searchState.state.is_failed = true
            } finally {
                searchState.state.is_loading = false
            }
        }, 500)

        const selectResult = (result: MapSearchResultT) => {
            searchState.query = null
            searchState.last_query = null

            emit('select', result)
            searchState.results = []
        }

        return { searchState, search, selectResult }
    },
})
