import {
    GisFormattedResponseT,
    GisGeocodeResponseResultItem,
    YandexFormattedResponseT,
    YandexGeoObjectT,
    YandexMetaDataAddressComponentT,
} from './map.types'

export const getCoordsFromYandexGeocodeResponse = (yandex_response: string) => {
    const coords = yandex_response.split(' ')
    const latitude = Number(coords[1])
    const longitude = Number(coords[0])

    return [latitude, longitude]
}

export const getFormattedResponseFromYandexGeoObject = (
    yandexGeoObject: YandexGeoObjectT
): YandexFormattedResponseT => {
    const address_components =
        yandexGeoObject.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components

    const object_address_components: Record<string, string> = {}

    address_components.forEach((component: YandexMetaDataAddressComponentT) => {
        object_address_components[component.kind] = component.name
    })

    const formatted_response: YandexFormattedResponseT = {
        full_address: yandexGeoObject.GeoObject.metaDataProperty.GeocoderMetaData.text,
        country: object_address_components.country || null,
        province: object_address_components.province || null,
        locality: object_address_components.locality || null,
        street: object_address_components.street || null,
        house: object_address_components.house || null,
    }

    return formatted_response
}

export const getFormattedResponseFromGisItem = (
    gis_item: GisGeocodeResponseResultItem
): GisFormattedResponseT => {
    const formatted_response: GisFormattedResponseT = {
        full_address: gis_item.full_name,
        province: null,
        street: null,
        country: null,
        house: null,
        locality: null,
    }

    if (gis_item.full_address_name) formatted_response.full_address = gis_item.full_address_name

    return formatted_response
}
