import { required, email, decimal, minLength, maxLength } from '@vuelidate/validators'

export const StateFormValidation = {
    tools: { LengthMoreThanZero: (v: any[]) => v.length >= 1 },
}

export const SenderFormValidation = {
    fio: { required },
    phone: { required, minLength: minLength(18), maxLength: maxLength(18) },
    city: { required },
    email: { required, email },
    service_center: { required },
    customer_requirement: { required },
    full_address: { required },
}

export const ToolFormValidation = {
    article: { required },
    type: { required },
    serial_number: { required },
}

export const CargoFormValidation = {
    weight: { required, decimal },
    length: { required, decimal },
    height: { required, decimal },
    width: { required, decimal },
}

export const AgreementsValidation = {
    agree_service: { isTrue: (v: boolean) => Boolean(v) === true },
    agree_guarantee: { isTrue: (v: boolean) => Boolean(v) === true },
    agree_contract: { isTrue: (v: boolean) => Boolean(v) === true },
}
