import { createApp } from 'vue'
import App from './App.vue'

import 'normalize.css'
import '@/assets/scss/index.scss'

import Multiselect from '@vueform/multiselect'

const app = createApp(App)
app.component('multiselect', Multiselect)
app.mount('#bosch-app')
